@import "mixins/breakpoints";
@import "variables";

.js-cmsplugin-moments-videos {
  padding-top: 1em;
  padding-bottom: 2em;
  background-color: transparent;
  color: $moments-hawkes-blue;

  svg {
    height: 4em;
  }
  .video-play-control {
    svg {
      height: 1em;
    }
  }
}

/* Video Selector */

.video-selector {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1em;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.video-selector > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: white;
  text-align: center;
  flex-grow: 1;
  flex: 1 1 0px;
  margin-right: 1em;
  background-color: #20282e;
  border-radius: 4px;
  padding-left: 10px;
  cursor: pointer;

  &:hover {
    background-color: #20282ee3;
  }

  &.selected {
    background-color: #293239;
  }

  &.selected:hover {
    background-color: #293239e3;
  }

  &:last-child {
    margin-right: 0px;
  }

  & > * {
    align-self: center;
    &:first-child {
      margin-right: 5px;
    }
  }
}

/*moments*/
.margin {
  fill: rgb(27, 35, 40);
}

.timeline-main-container {
  position: relative;
}

.timeline__timeline-container {
  width: 100%;
}

.scrubber {
  fill-opacity: 0;
  pointer-events: none;
}

.play-area {
  fill: rgb(23, 27, 31);
  cursor: pointer;
}

.border-rect {
  fill: rgb(4, 201, 172);
  pointer-events: none;
  cursor: pointer;
  width: calc(100% - 44px);
}

.trimmed-area {
  fill: rgb(21, 36, 38);
  pointer-events: none;
  cursor: pointer;
}

.out-of-bounds__rectangle {
  pointer-events: none;
}

.timeline-icon__container .stroke {
  fill: rgb(21, 36, 38);
}

.timeline-icon__container .fill {
  cursor: pointer;
  pointer-events: bounding-box;
}

.timeline-icon__container .stroke,
.timeline-icon__container .circle {
  pointer-events: none;
}

.timeline-icon__container .fill,
.timeline-icon__container .stroke,
.timeline-icon__container .circle {
  transition: fill 100ms ease 0s;
}

.start-marker {
}

.fence__styled-fence {
  pointer-events: none;
}

.fence__styled-fence .outer {
  color: rgb(4, 201, 172);
}
.fence__styled-fence .inner {
  color: rgb(14, 104, 93);
}

.fence__hit-box {
  fill-opacity: 0;
}

.video-player__video {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
}

.cursor__cursor-path {
  stroke: white;
  stroke-width: 2;
  pointer-events: none;
}

.timeline__container {
  width: 100%;
  overflow: visible;
}

.dot__dotted-line {
  fill: rgb(60, 68, 78);
}

.moments-editor__container {
  height: 100%;
  background-color: rgb(35, 43, 50);
}

.video-frame__container {
  min-width: 0px;
  min-height: 0px;
  display: flex;
  align-content: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(22, 26, 30);
}

.moments-editor__video-container {
  min-width: 0px;
  min-height: 0px;
  display: flex;
  align-content: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(22, 26, 30);
  margin: 0px 0px 0px;
}

.moments-editor__timeline-container {
  padding-top: 16px;
  background-color: rgb(27, 35, 40);
  display: block;
  border-radius: 0 0 4px 4px;
}

/* generic Styles*/

.generic .stroke {
  fill: rgb(21, 36, 38);
}

.generic .fill {
  fill: rgb(43, 162, 230);
}

.generic .circle {
  fill: transparent;
}

.generic:hover .circle {
  fill: rgb(27, 56, 74);
}

.generic:hover .fill {
  fill: rgb(45, 177, 252);
}

.generic:hover .stroke {
  fill: transparent;
}

/* kill Styles*/

.kill .stroke {
  fill: rgb(21, 36, 38);
}

.kill .fill {
  fill: rgb(43, 162, 230);
}

.kill .circle {
  fill: transparent;
}

.kill:hover .circle {
  fill: rgb(27, 56, 74);
}

.kill:hover .fill {
  fill: rgb(45, 177, 252);
}

.kill:hover .stroke {
  fill: transparent;
}
/* death Styles*/

.death .stroke {
  fill: rgb(21, 36, 38);
}

.death .fill {
  fill: rgb(232, 77, 83);
}

.death .circle {
  fill: transparent;
}

.death:hover .circle {
  fill: rgb(69, 37, 42);
}

.death:hover .fill {
  fill: rgb(255, 83, 89);
}

.death:hover .stroke {
  fill: transparent;
}

/* game-win styles */

.game-win .stroke {
  fill: rgb(21, 36, 38);
}

.game-win .fill {
  fill: rgb(227, 152, 46);
}

.game-win .circle {
  fill: transparent;
}

.game-win:hover .stroke {
  fill: transparent;
}

.game-win:hover .fill {
  fill: rgb(250, 166, 48);
}

.game-win:hover .circle {
  fill: rgb(68, 54, 34);
}

/* headshot Styles */

.headshot .stroke {
  fill: rgb(21, 36, 38);
}

.headshot .fill {
  fill: rgb(43, 162, 230);
}

.headshot .circle {
  fill: transparent;
}

.headshot:hover .stroke {
  fill: transparent;
}

.headshot:hover .fill {
  fill: rgb(45, 177, 252);
}

.headshot:hover .circle {
  fill: rgb(27, 56, 74);
}

/* moments tooltip */
.timeline__styled-tooltip {
  font-weight: 700;
  font-size: 12px;
  font-family: $body-font-family;
  line-height: 16px;
  color: rgb(171, 180, 204);
  opacity: 1;
  padding: 8px 14px;
  background: rgb(22, 26, 31);
}

.svg-tooltip.place-top:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -8px;
  left: 50%;
  margin-left: -10px;
}

.svg-tooltip.place-top:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -6px;
  left: 50%;
  margin-left: -8px;
}

.svg-tooltip:before,
.svg-tooltip:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}

.Timeline__StyledReactTooltip::after {
  border-top-color: rgb(22, 26, 31);
}

.svg-tooltip.type-dark.place-top:after {
  border-top-color: #222;
  border-top-style: solid;
  border-top-width: 6px;
}

.svg-tooltip.show {
  opacity: 0.9;
  margin-top: 0px;
  margin-left: 0px;
  visibility: visible;
}

.svg-tooltip {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  left: 999em;
  opacity: 0;
  padding: 8px 21px;
  position: absolute;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  top: 999em;
  visibility: hidden;
  z-index: 999;
}

.svg-tooltip.place-top {
  margin-top: -10px;
}

.svg-tooltip.type-dark.place-top::after {
  border-top-color: #222;
  border-top-style: solid;
  border-top-width: 6px;
}

.svg-tooltip.place-top::after {
  border-top-color: #222;
  border-top-style: solid;
  border-top-width: 6px;
}

.svg-tooltip.type-dark {
  color: #fff;
  background-color: #222;
}

#tooltip {
  background: cornsilk;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}
